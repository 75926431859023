exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-esg-js": () => import("./../../../src/pages/esg.js" /* webpackChunkName: "component---src-pages-esg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-brand-js": () => import("./../../../src/pages/our-brand.js" /* webpackChunkName: "component---src-pages-our-brand-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-our-people-js": () => import("./../../../src/pages/our-people.js" /* webpackChunkName: "component---src-pages-our-people-js" */),
  "component---src-pages-our-spokespeople-js": () => import("./../../../src/pages/our-spokespeople.js" /* webpackChunkName: "component---src-pages-our-spokespeople-js" */),
  "component---src-pages-section-172-1-statements-js": () => import("./../../../src/pages/section-172-1-statements.js" /* webpackChunkName: "component---src-pages-section-172-1-statements-js" */),
  "component---src-pages-slavery-and-human-trafficking-statement-js": () => import("./../../../src/pages/slavery-and-human-trafficking-statement.js" /* webpackChunkName: "component---src-pages-slavery-and-human-trafficking-statement-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-templates-charity-news-js": () => import("./../../../src/templates/charity-news.js" /* webpackChunkName: "component---src-templates-charity-news-js" */),
  "component---src-templates-charity-page-js": () => import("./../../../src/templates/charity-page.js" /* webpackChunkName: "component---src-templates-charity-page-js" */),
  "component---src-templates-company-statement-js": () => import("./../../../src/templates/company-statement.js" /* webpackChunkName: "component---src-templates-company-statement-js" */),
  "component---src-templates-esg-page-js": () => import("./../../../src/templates/esg-page.js" /* webpackChunkName: "component---src-templates-esg-page-js" */),
  "component---src-templates-in-the-news-js": () => import("./../../../src/templates/in-the-news.js" /* webpackChunkName: "component---src-templates-in-the-news-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/press-release.js" /* webpackChunkName: "component---src-templates-press-release-js" */),
  "component---src-templates-profile-page-js": () => import("./../../../src/templates/profile-page.js" /* webpackChunkName: "component---src-templates-profile-page-js" */)
}

